import { Component } from "react";

class BrokerLoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientCode: "",
      pin: 0,
      totp: 0
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.setAccessToken(this.state);
  }

  handleChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = val;
      return newState;
    });
  }

  render() {
    return (
      <div className={this.props.showBrokerLoginModal ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Angel Login Form</h5>
          </header>

          <section className="modal-card-body">
            <form onSubmit={this.handleSubmit} id="angel-login-form">
              <div className="field">
                <label htmlFor="client-code" className="label">Client Code:</label>
                <div className="control">
                  <input
                    type="text"
                    id="client-code"
                    name="clientCode"
                    className="input"
                    onChange={this.handleChange}
                    value={this.state.clientCode}
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="pin" className="label">Pin:</label>
                <div className="control">
                  <input
                    id="pin"
                    name="pin"
                    type="number"
                    className="input"
                    value={this.state.pin}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="field">
                <label htmlFor="totp" className="label">TOTP:</label>
                <div className="control">
                  <input
                    id="totp"
                    name="totp"
                    type="number"
                    className="input"
                    value={this.state.totp}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <button type="submit" className="button is-link" form="angel-login-form">Login</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default BrokerLoginModal;