import { Component } from "react";

import Logs from "./logs.jsx";
import Navbar from "./navbar.jsx";
import Settings from "./settings.jsx";
import Orderbook from "./orderbook.jsx";
import NiftyPage from "./niftyPage.jsx";
import BankniftyPage from "./bankniftyPage.jsx";
import TradeSettings from "./tradeSettings.jsx";
import BrokerLoginModal from "./brokerLoginModal.jsx";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "nifty"
    }
  }

  changePage = page => {
    console.log(`Changing to ${page}`);

    this.setState({
      currentPage: page
    });
  }

  render() {
    let page;

    if (this.state.currentPage === "nifty") {
      page = <NiftyPage
        niftyInputs={this.props.niftyInputs}
        algoStatus={this.props.algoStatus}
        getAlgoStatus={this.props.getAlgoStatus}
        getInputs={this.props.getInputs}
        handleInputChange={this.props.handleInputChange}
        handleCEPEChange={this.props.handleCEPEChange}
        saveInputs={this.props.saveInputs}
        startAlgo={this.props.startAlgo}
        stopAlgo={this.props.stopAlgo}
        squareoffAlgo={this.props.squareoffAlgo}
        breakEntry={this.props.breakEntry}
        marketEntry={this.props.marketEntry}
        _50PctSL={this.props._50PctSL}
        highLowSL={this.props.highLowSL}
        incrementSL={this.props.incrementSL}
        openTarget={this.props.openTarget}
        partialTarget={this.props.partialTarget}
        fullTarget={this.props.fullTarget}
      />
    } else if (this.state.currentPage === "banknifty") {
      page = <BankniftyPage
        bankniftyInputs={this.props.bankniftyInputs}
        algoStatus={this.props.algoStatus}
        getAlgoStatus={this.props.getAlgoStatus}
        getInputs={this.props.getInputs}
        handleInputChange={this.props.handleInputChange}
        handleCEPEChange={this.props.handleCEPEChange}
        saveInputs={this.props.saveInputs}
        startAlgo={this.props.startAlgo}
        stopAlgo={this.props.stopAlgo}
        squareoffAlgo={this.props.squareoffAlgo}
        breakEntry={this.props.breakEntry}
        marketEntry={this.props.marketEntry}
        _50PctSL={this.props._50PctSL}
        highLowSL={this.props.highLowSL}
        incrementSL={this.props.incrementSL}
        openTarget={this.props.openTarget}
        partialTarget={this.props.partialTarget}
        fullTarget={this.props.fullTarget}
      />
    } else if (this.state.currentPage === "tradeSettings") {
      page = <TradeSettings
        tradeSettings={this.props.tradeSettings}
        getTradeSettings={this.props.getTradeSettings}
        saveTradeSettings={this.props.saveTradeSettings}
        handleTradeSettingChange={this.props.handleTradeSettingChange}
      />
    } else if (this.state.currentPage === "orderbook") {
      page = <Orderbook
        orders={this.props.orders}
        getOrders={this.props.getOrders}
        clearOrders={this.props.clearOrders}
      />
    } else if (this.state.currentPage === "logs") {
      page = <Logs
        logs={this.props.logs}
        getLogs={this.props.getLogs}
        clearLogs={this.props.clearLogs}
      />
    } else if (this.state.currentPage === "settings") {
      page = <Settings
        stopServer={this.props.stopServer}
        startServer={this.props.startServer}
        serverStatus={this.props.serverStatus}
        getServerStatus={this.props.getServerStatus}
      />
    }

    return (
      <div className="main-page">
        <Navbar
          username={this.props.username}
          handleLogout={this.props.handleLogout}
        />

        <div className="tabs is-toggle is-toggle-rounded is-fullwidth p-2 m-2">
          <ul>
            <li
              className={this.state.currentPage === "nifty" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("nifty")}
                className={this.state.currentPage !== "nifty" ? "has-background-white" : ""}
              >
                Nifty
              </a>
            </li>

            <li
              className={this.state.currentPage === "banknifty" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("banknifty")}
                className={this.state.currentPage !== "banknifty" ? "has-background-white" : ""}
              >
                Banknifty
              </a>
            </li>

            <li
              className={this.state.currentPage === "orderbook" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("orderbook")}
                className={this.state.currentPage !== "orderbook" ? "has-background-white" : ""}
              >
                Orderbook
              </a>
            </li>

            <li
              className={this.state.currentPage === "logs" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("logs")}
                className={this.state.currentPage !== "logs" ? "has-background-white" : ""}
              >
                Logs
              </a>
            </li>

            <li
              className={this.state.currentPage === "tradeSettings" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("tradeSettings")}
                className={this.state.currentPage !== "tradeSettings" ? "has-background-white" : ""}
              >
                Trade Settings
              </a>
            </li>

            <li
              className={this.state.currentPage === "settings" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("settings")}
                className={this.state.currentPage !== "settings" ? "has-background-white" : ""}
              >
                Settings
              </a>
            </li>
          </ul>
        </div>

        <BrokerLoginModal
          setAccessToken={this.props.setAccessToken}
          showBrokerLoginModal={this.props.showBrokerLoginModal}
        />

        {page}
      </div>
    );
  }
}

export default MainPage;
