import { Component } from "react";

class TradeSettings extends Component {
  componentDidMount() {
    this.props.getTradeSettings();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.saveTradeSettings();
  }

  render() {
    return (
      <div className="columns">
        <div className="column is-three-fifths-tablet is-offset-one-fifth-tablet">
          <div className="card">
            <header className="card-header">
              <h5 className="card-header-title">Trade Settings</h5>
            </header>

            <section className="card-content">
              <form
                id="trade-settings-form"
                onSubmit={this.handleSubmit}
              >
                <div className="field">
                  <label className="label" htmlFor="api-key">API Key:</label>
                  <div className="control">
                    <input
                      id="api-key"
                      name="apiKey"
                      className="input"
                      value={this.props.tradeSettings.apiKey}
                      onChange={this.props.handleTradeSettingChange}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="api-secret">API Secret:</label>
                  <div className="control">
                    <input
                      id="api-secret"
                      name="apiSecret"
                      className="input"
                      value={this.props.tradeSettings.apiSecret}
                      onChange={this.props.handleTradeSettingChange}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="trade-limit">Trade Limit:</label>
                  <div className="control">
                    <input
                      type="number"
                      id="trade-limit"
                      name="tradeLimit"
                      className="input"
                      value={this.props.tradeSettings.tradeLimit}
                      onChange={this.props.handleTradeSettingChange}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="trading-mode">Trading Mode:</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="trading-mode"
                        name="tradingMode"
                        value={this.props.tradeSettings.tradingMode}
                        onChange={this.props.handleTradeSettingChange}
                      >
                        <option value="Paper">Paper</option>
                        <option value="Live">Live</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </section>

            <footer className="card-footer">
              <button
                type="submit"
                form="trade-settings-form"
                className="card-footer-item button is-success"
              >
                Save
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default TradeSettings;