import { Component } from "react";

class NiftyPage extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   expiry: "",
    //   expiryType: "Weekly",
    //   strikeDistance: 0,
    //   timeframe: "1 M",
    //   maxProfit: 0,
    //   maxDrawdown: 0,
    //   capitalDeployed: 0,
    //   isCEEnabled: false,
    //   isPEEnabled: false
    // }

    this.intervalID = null;
    this.page = "nifty";
  }

  componentDidMount() {
    this.props.getInputs(this.page);
    this.intervalID = setInterval(this.props.getAlgoStatus, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.intervalID = null;
  }

  handleInputKeyDown = e => {
    if (e.keyCode === 13) {
      console.log("Enter Pressed");

      this.props.saveInputs(this.page);
    }
  }

  render() {
    return (
      <div>
        <div className="card m-4">
          <header className="card-header">
            <h5 className="card-header-title">Nifty Algo</h5>
          </header>

          <section className="card-content">
            <div className="input-section">
              <div className="columns is-centered">
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="lots">Lots:</label>
                    <div className="control">
                      <input
                        id="lots"
                        name="lots"
                        type="number"
                        className="input"
                        value={this.props.niftyInputs.lots}
                        onKeyDown={this.handleInputKeyDown}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="expiry">Expiry:</label>
                    <div className="control">
                      <input
                        id="expiry"
                        type="date"
                        name="expiry"
                        className="input"
                        onKeyDown={this.handleInputKeyDown}
                        value={this.props.niftyInputs.expiry}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="expiry-type">Expiry Type:</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          id="expiry-type"
                          name="expiryType"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleInputChange(this.page, e)}
                          value={this.props.niftyInputs.expiryType}
                        >
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="strike-distance">Strike Distance:</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        id="strike-distance"
                        name="strikeDistance"
                        onKeyDown={this.handleInputKeyDown}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                        value={this.props.niftyInputs.strikeDistance}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="max-profit">Take Profit:</label>
                    <div className="control">
                      <input
                        type="number"
                        id="max-profit"
                        name="maxProfit"
                        className="input"
                        onKeyDown={this.handleInputKeyDown}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                        value={this.props.niftyInputs.maxProfit}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="max-drawdown">Max Drawdown:</label>
                    <div className="control">
                      <input
                        type="number"
                        id="max-drawdown"
                        className="input"
                        name="maxDrawdown"
                        onKeyDown={this.handleInputKeyDown}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                        value={this.props.niftyInputs.maxDrawdown}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="trail-sl-activation">Trail SL Activation:</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        id="trail-sl-activation"
                        name="trailSLActivation"
                        onKeyDown={this.handleInputKeyDown}
                        value={this.props.niftyInputs.trailSLActivation}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-tablet">
                  <div className="field">
                    <label className="label" htmlFor="trail-sl">Premium SL:</label>
                    <div className="control">
                      <input
                        type="number"
                        id="trail-sl"
                        name="trailSL"
                        className="input"
                        onKeyDown={this.handleInputKeyDown}
                        value={this.props.niftyInputs.trailSL}
                        onChange={e => this.props.handleInputChange(this.page, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-12">
                  <button className="button is-dark is-fullwidth" onClick={() => this.props.saveInputs(this.page)}>Save</button>
                </div>
              </div>

              <div className="columns">
                <div className="column is-1-tablet">
                  <div className="field">
                    <div className="control">
                      <label className="radio">
                        <input
                          type="checkbox"
                          name="isCEEnabled"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleCEPEChange(this.page, e)}
                          checked={this.props.niftyInputs.isCEEnabled}
                        />
                        &nbsp;
                        CE Buy
                      </label>
                    </div>
                  </div>
                </div>
                <div className="column is-1-tablet">
                  <div className="field">
                    <div className="control">
                      <label className="radio">
                        <input
                          type="checkbox"
                          name="isPEEnabled"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleCEPEChange(this.page, e)}
                          checked={this.props.niftyInputs.isPEEnabled}
                        />
                        &nbsp;
                        PE Buy
                      </label>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="radio">
                        <input
                          value="ONE_MINUTE"
                          type="radio"
                          name="timeframe"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleInputChange(this.page, e)}
                          checked={this.props.niftyInputs.timeframe === "ONE_MINUTE"}
                        />
                        &nbsp;
                        1 M
                      </label>
                      <label className="radio">
                        <input
                          value="THREE_MINUTE"
                          type="radio"
                          name="timeframe"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleInputChange(this.page, e)}
                          checked={this.props.niftyInputs.timeframe === "THREE_MINUTE"}
                        />
                        &nbsp;
                        3 M
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="timeframe"
                          value="FIVE_MINUTE"
                          onKeyDown={this.handleInputKeyDown}
                          onChange={e => this.props.handleInputChange(this.page, e)}
                          checked={this.props.niftyInputs.timeframe === "FIVE_MINUTE"}
                        />
                        &nbsp;
                        5 M
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <hr />

            <div className="has-text-centered">
              <p>
                <span className="has-text-weight-bold">Currently Set Inputs:</span>
                &nbsp;
                Capital Deployed: <strong>{this.props.niftyInputs.capitalDeployed}</strong> |
                Expiry: <strong>{this.props.niftyInputs.expiry}</strong> |
                Expiry Type: <strong>{this.props.niftyInputs.expiryType}</strong> |
                Strike Distance: <strong>{this.props.niftyInputs.strikeDistance}</strong> |
                Max Profit: <strong>{this.props.niftyInputs.maxProfit}</strong> |
                Max Drawdown: <strong>{this.props.niftyInputs.maxDrawdown}</strong> |
                Trail SL Activation: <strong>{this.props.niftyInputs.trailSLActivationPct} %</strong> |
                Trail SL: <strong>{this.props.niftyInputs.trailSLPct} %</strong>
                Is CE Enabled: <strong>{this.props.niftyInputs.isCEEnabled ? "Yes" : "No"}</strong> |
                Is PE Enabled: <strong>{this.props.niftyInputs.isPEEnabled ? "Yes" : "No"}</strong> |
                Timeframe: <strong>{this.props.niftyInputs.timeframe}</strong> |
              </p>
            </div> */}
          </section>
        </div>

        <div className={`card ${this.props.algoStatus.nStarted ? "green-shadow" : "red-shadow"} m-4`}>
          <section className="card-content">
            <div className="btn-section columns">
              <div className="column p-0">
                {
                  this.props.algoStatus.nStarted ?
                  <button
                    className="button is-danger is-fullwidth"
                    onClick={() => this.props.stopAlgo(this.page)}
                  >
                    Stop
                  </button> :
                  <button
                    className="button is-link is-fullwidth"
                    onClick={() => this.props.startAlgo(this.page)}
                  >
                    Start
                  </button>
                }
              </div>
              <div className="column p-0">
                <button
                  className="button is-primary is-fullwidth"
                  onClick={() => this.props.breakEntry(this.page)}
                >
                  Break
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-primary is-fullwidth"
                  onClick={() => this.props.marketEntry(this.page)}
                >
                  Market
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-info is-fullwidth"
                  onClick={() => this.props._50PctSL(this.page)}
                >
                  50% SL
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-info is-fullwidth"
                  onClick={() => this.props.highLowSL(this.page)}
                >
                  H/L SL
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-info is-fullwidth"
                  onClick={() => this.props.incrementSL(this.page)}
                >
                  Increment SL
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-success is-fullwidth"
                  onClick={() => this.props.openTarget(this.page)}
                >
                  Open
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-success is-fullwidth"
                  onClick={() => this.props.partialTarget(this.page)}
                >
                  Partial
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-success is-fullwidth"
                  onClick={() => this.props.fullTarget(this.page)}
                >
                  Full
                </button>
              </div>
              <div className="column p-0">
                <button
                  className="button is-warning is-fullwidth"
                  onClick={() => this.props.squareoffAlgo(this.page)}
                >
                  Sqr. off
                </button>
              </div>
            </div>

            <div className="stats-section columns">
              <div className="column">
                <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th>Statistic</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Next Move:</th>
                      <td>{this.props.algoStatus.nNextMove}</td>
                    </tr>
                    <tr>
                      <th>Entered Ins:</th>
                      <td>{this.props.algoStatus.nEnteredIns}</td>
                    </tr>
                    <tr>
                      <th>Entered Price:</th>
                      <td>{this.props.algoStatus.nEnteredPrice}</td>
                    </tr>
                    <tr>
                      <th>Entered Qty:</th>
                      <td>{this.props.algoStatus.nEnteredQty}</td>
                    </tr>
                    <tr>
                      <th>Remaining Qty:</th>
                      <td>{this.props.algoStatus.nRemainingQty}</td>
                    </tr>
                    <tr>
                      <th>LTP:</th>
                      <td>{this.props.algoStatus.nLtp}</td>
                    </tr>
                    <tr>
                      <th>Running P&L:</th>
                      <td>{this.props.algoStatus.nRunningPnl}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="column">
                <table className="table is-bordered is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th>Statistic</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Locked SL:</th>
                      <td>{this.props.algoStatus.nLockedSL}</td>
                    </tr>
                    <tr>
                      <th>Locked TP:</th>
                      <td>{this.props.algoStatus.nLockedTP}</td>
                    </tr>
                    <tr>
                      <th>Trailed SL:</th>
                      <td>{this.props.algoStatus.nTrailedSL}</td>
                    </tr>
                    <tr>
                      <th>TP Type:</th>
                      <td>{this.props.algoStatus.nTPType}</td>
                    </tr>
                    <tr>
                      <th>SL Type:</th>
                      <td>{this.props.algoStatus.nSLType}</td>
                    </tr>
                    <tr>
                      <th>Entry Type:</th>
                      <td>{this.props.algoStatus.nEntryType}</td>
                    </tr>
                    <tr>
                      <th>Booked P&L:</th>
                      <td>{this.props.algoStatus.nBookedPnl}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default NiftyPage;