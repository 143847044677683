import "./App.css";
import Cookies from "js-cookie";
import { Component } from "react";
import queryString from "query-string";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// Components
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";

const options = {
  position: "top-right",
  pauseOnHover: true,
  closeOnClick: true,
  theme: "dark",
  autoClose: 2500
}

class App extends Component {
  constructor(props) {
    super(props);

    console.log(process.env.NODE_ENV);
    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

    console.log(this.URL);

    this.state = {
      loggedIn: localStorage.getItem("jwt-token") ? true : false,
      username: "",
      niftyInputs: {},
      bankniftyInputs: {},
      algoStatus: {},
      tradeSettings: {},
      orders: [],
      logs: [],
      serverStatus: "",
      showBrokerLoginModal: false
    }
  }

  request = async (route, method, body, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify(body)
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

      return json

    } else if (json.type === "generateToken") {
      console.log(`Redirecting to ${json.url}`);
      window.location = json.url;

      return json

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }

      return json
    }
  }

  componentDidMount = async () => {
    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.fetchBrokerAccessToken();
      }
    }
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  handleLogin = async data => {
    console.log(data);
    const json = await this.request("login/", "POST", data, false);

    if (json.type === "success") {
      localStorage.setItem("jwt-token", json.jwtToken);

      this.setState({
        loggedIn: true
      }, this.fetchBrokerAccessToken);
    }
  }

  handleLogout = () => {
    localStorage.removeItem("jwt-token");
    this.setState({loggedIn: false});
  }

  fetchBrokerAccessToken = async () => {
    let parsed = queryString.parse(window.location.search);
    let authCode = parsed.auth_code;

    console.log(authCode);

    if (authCode) {
      await this.request("set-access-token/", "POST", {authCode: authCode});
      window.location.search = "";
    } else if (this.state.loggedIn) {
      const json = await this.request("broker-token/", "GET");

      if (json.type === "success") {
        this.setState({
          username: json.username
        }, this.getAlgoStatus);
      }
    }
  }

  setAccessToken = async state => {
    const json = await this.request("set-access-token/", "POST", state);

    if (json.type === "success") {
      this.fetchBrokerAccessToken();
    }
  }

  getAlgoStatus = async () => {
    const json = await this.request("algo/", "GET", null, true, false);

    if (json.type === "success") {
      this.setState({algoStatus: json.data});
    }
  }

  getInputs = async page => {
    const json = await this.request(`algo/inputs/${page}/`, "GET");

    if (json.type === "success") {
      if (page === "nifty") {
        this.setState({niftyInputs: json.data});
      } else if (page === "banknifty") {
        this.setState({bankniftyInputs: json.data});
      }
    }
  }

  handleInputChange = (page, e) => {
    const name = e.currentTarget.name;
    const val = e.currentTarget.value;

    console.log(name);
    console.log(val);

    this.setState(prevState => {
      const newState = { ...prevState };
      const inputs = { ...newState[`${page}Inputs`] };

      inputs[name] = val;

      newState[`${page}Inputs`] = inputs;
      return newState;
    }, () => {
      if (name === "timeframe") {
        this.saveInputs(page);
      }
    });
  }

  handleCEPEChange = (page, e) => {
    const name = e.currentTarget.name;

    this.setState(prevState => {
      const newState = { ...prevState };
      const inputs = { ...newState[`${page}Inputs`] };

      inputs[name] = !inputs[name];

      newState[`${page}Inputs`] = inputs;
      return newState;
    }, () => this.saveInputs(page));
  }

  saveInputs = async page => {
    const json = await this.request(`algo/inputs/save/${page}/`, "POST", {data: this.state[`${page}Inputs`]});

    if (json.type === "success") {
      await this.getInputs(page);
    }
  }

  startAlgo = async page => {
    const json = await this.request(`algo/start/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  stopAlgo = async page => {
    const json = await this.request(`algo/stop/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  squareoffAlgo = async page => {
    const json = await this.request(`algo/squareoff/${page}/`, "DELETE");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  breakEntry = async page => {
    const json = await this.request(`algo/break-entry/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  marketEntry = async page => {
    const json = await this.request(`algo/market-entry/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  _50PctSL = async page => {
    const json = await this.request(`algo/50-pct-sl/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  highLowSL = async page => {
    const json = await this.request(`algo/high-low-sl/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  incrementSL = async page => {
    const json = await this.request(`algo/increment-sl/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  openTarget = async page => {
    const json = await this.request(`algo/open-target/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  partialTarget = async page => {
    const json = await this.request(`algo/partial-target/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  fullTarget = async page => {
    const json = await this.request(`algo/full-target/${page}/`, "PUT");

    if (json.type === "success") {
      await this.getAlgoStatus();
    }
  }

  getTradeSettings = async () => {
    const json = await this.request("trade-settings/", "GET");

    if (json.type === "success") {
      this.setState({tradeSettings: json.data});
    }
  }

  handleTradeSettingChange = e => {
    const name = e.currentTarget.name;
    const val = e.currentTarget.value;

    console.log(name);
    console.log(val);

    this.setState(prevState => {
      const newState = { ...prevState };
      const tradeSettings = { ...newState.tradeSettings };

      tradeSettings[name] = val;

      newState.tradeSettings = tradeSettings;
      return newState;
    });
  }

  saveTradeSettings = async () => {
    await this.request("trade-settings/save/", "POST", {data: this.state.tradeSettings});
    await this.getTradeSettings();
  }

  getOrders = async () => {
    const json = await this.request("orders/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.orders = json.orders;
      return newState;
    });

    console.log(json.orders);
  }

  clearOrders = async () => {
    await this.request("orders/clear/", "DELETE");
    this.getOrders();
  }

  getLogs = async () => {
    const json = await this.request("logs/", "GET");

    this.setState(prevState => {
      const newState = { ...prevState };
      newState.logs = json.logs;
      return newState;
    });

    console.log(json.logs);
  }

  clearLogs = async () => {
    await this.request("logs/clear/", "DELETE");
    this.getLogs();
  }

  getServerStatus = async () => {
    const json = await this.request("server/status/", "GET");

    if (json.type === "success") {
      if (json.data === 0) {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState.serverStatus = "running";
          return newState;
        });
      } else {
        this.setState(prevState => {
          const newState = { ...prevState };
          newState.serverStatus = "stopped";
          return newState;
        });
      }
    }
  }

  startServer = async () => {
    const json = await this.request("server/start/", "PUT");

    if (json.type === "success") {
      this.getServerStatus();
    }
  }

  stopServer = async () => {
    const json = await this.request("server/stop/", "PUT");

    if (json.type === "success") {
      this.getServerStatus();
    }
  }

  render() {
    let page = null;

    if (!this.state.loggedIn) {
      page = <LoginForm
        handleLogin={this.handleLogin}
      />

    } else {
      page = <MainPage
        URL={this.URL}
        username={this.state.username}
        handleLogout={this.handleLogout}
        setAccessToken={this.setAccessToken}
        showBrokerLoginModal={this.state.showBrokerLoginModal}

        niftyInputs={this.state.niftyInputs}
        bankniftyInputs={this.state.bankniftyInputs}
        algoStatus={this.state.algoStatus}
        getAlgoStatus={this.getAlgoStatus}
        getInputs={this.getInputs}
        handleInputChange={this.handleInputChange}
        handleCEPEChange={this.handleCEPEChange}
        saveInputs={this.saveInputs}
        startAlgo={this.startAlgo}
        stopAlgo={this.stopAlgo}
        squareoffAlgo={this.squareoffAlgo}
        breakEntry={this.breakEntry}
        marketEntry={this.marketEntry}
        _50PctSL={this._50PctSL}
        highLowSL={this.highLowSL}
        incrementSL={this.incrementSL}
        openTarget={this.openTarget}
        partialTarget={this.partialTarget}
        fullTarget={this.fullTarget}

        tradeSettings={this.state.tradeSettings}
        getTradeSettings={this.getTradeSettings}
        saveTradeSettings={this.saveTradeSettings}
        handleTradeSettingChange={this.handleTradeSettingChange}

        orders={this.state.orders}
        getOrders={this.getOrders}
        clearOrders={this.clearOrders}

        logs={this.state.logs}
        getLogs={this.getLogs}
        clearLogs={this.clearLogs}

        stopServer={this.stopServer}
        startServer={this.startServer}
        serverStatus={this.state.serverStatus}
        getServerStatus={this.getServerStatus}
      />
    }

    return (
      <div className="App">
        {page}
        <ToastContainer />
      </div>
    );
  }
}

export default App;